import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "\n      h-5\n      px-2\n      rounded\n      font-display\n      text-md\n      md:text-sm\n      text-amff-black-2024\n      cursor-pointer\n      inline-flex\n      items-center\n      mb-5\n      md:mb-0\n      lg:hover:text-amff-dark-gray\n      lg:hover:bg-amff-yellow-2024\n    " }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ArrowIcon = _resolveComponent("ArrowIcon")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createTextVNode(_toDisplayString(_ctx.$t("actions.share")) + " ", 1),
    _createVNode(_component_ArrowIcon, {
      fill: "currentColor",
      class: "ml-2 w-4 h-4"
    })
  ]))
}