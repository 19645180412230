
import { defineComponent, ref, watchEffect, computed, toRefs } from "vue";
import { useEvents } from "@/composables/useEvents";
import { EventModel } from "@/domain/models/EventModel";
import Label from "@/components/Label.vue";
import PageTitle from "@/components/PageTitle.vue";
import EventCard from "@/components/EventCard.vue";
import Button from "@/components/Button.vue";
import ArrowIcon from "@/components/icons/ArrowIcon.vue";
import LocationIcon from "@/components/icons/LocationIcon.vue";
import SeeTheFullProgram from "@/components/SeeTheFullProgram.vue";
import FollowFestival from "@/components/FollowFestival.vue";
import { formatDay } from "@/utils/dateTimeFormatter";
import { useRouteWithLang } from "@/composables/useRouteWithLang";
import TrailerModal from "@/components/TrailerModal.vue";
import ShareButton from "@/components/ShareButton.vue";
import ShareModal from "@/components/ShareModal.vue";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import EventImage from "@/components/EventImage.vue";
import BackgroundVideo from "@/components/BackgroundVideo.vue";
import AnchorLink from "@/components/AnchorLink.vue";
import { cdn } from "@/utils/cdn";

export default defineComponent({
  components: {
    Label,
    PageTitle,
    Button,
    ArrowIcon,
    LocationIcon,
    SeeTheFullProgram,
    FollowFestival,
    EventCard,
    TrailerModal,
    ShareButton,
    ShareModal,
    LoadingIndicator,
    EventImage,
    BackgroundVideo,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    slug: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { isFetchingEvents, getEventBySlug, filterEvents } = useEvents();
    const event = ref<EventModel | undefined>(undefined);
    const { currentLang } = useRouteWithLang();
    const isTrailerModalOpen = ref(false);
    const isShareModalOpen = ref(false);
    const slug = toRefs(props).slug;
    watchEffect(() => {
      getEvent(slug.value);
    });

    async function getEvent(slug: string) {
      event.value = await getEventBySlug(slug);
    }
    const showImage = ref(false);

    const eventTypeLabel = computed(() => {
      if (event.value?.type === "concert") {
        return "events.event_types.music";
      }
      if (event.value?.type === "projection") {
        return "events.event_types.movie";
      }
      if (event.value?.type === "conference") {
        return "events.event_types.talk";
      }
      return null;
    });

    const videoBackground = computed(() => {
      if (!event.value) return null;

      if (event.value.videoBackground) {
        return event.value.videoBackground;
      }

      if (event.value.media?.videoBackground) {
        return event.value.media.videoBackground;
      }

      if (event.value.media?.videoTrailer) {
        return event.value.media.videoTrailer;
      }

      return null;
    });

    const sameDayEvents = computed(() => [
      ...new Set(
        filterEvents({
          day: event.value?.day,
        }).filter((e) => e.slug !== event.value?.slug)
      ),
    ]);

    const sameTypeEvents = computed(() => {
      var day = event.value?.day;
      var id = event.value?.id;
      var hour = event.value?.hour;
      var sameTypeEvents = [
        ...new Set(
          filterEvents({
            eventType: event.value?.type,
          }).filter((e) =>
            sameDayEvents.value.filter((sde) => sde.slug !== e.slug)
          )
        ),
      ];
      var past = sameTypeEvents.filter((e) => {
        if (day) {
          return e.day < day;
        }
        return false;
      });
      var future = sameTypeEvents.filter((e) => {
        if (day) {
          return e.day > day;
        }
        return false;
      });

      sameTypeEvents.forEach((e) => {
        if (day) {
          if (e.day === day && e.id !== id) {
            if (hour) {
              if (e.hour >= hour) {
                future.unshift(e);
              } else {
                past.push(e);
              }
            }
          }
        }
      });

      return future.concat(past);
    });

    return {
      event,
      showImage,
      eventTypeLabel,
      videoBackground,
      formatDay,
      currentLang,
      sameDayEvents,
      isTrailerModalOpen,
      isShareModalOpen,
      sameTypeEvents,
      AnchorLink,
      isFetchingEvents,
      cdn,
    };
  },
});
