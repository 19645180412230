
import { defineComponent } from "vue";
import AnchorLink from "@/components/AnchorLink.vue";
import Button from "@/components/Button.vue";
import ArrowIcon from "@/components/icons/ArrowIcon.vue";

export default defineComponent({
  components: {
    ArrowIcon,
    Button,
  },
  setup() {
    return { AnchorLink };
  },
});
