<template>
  <a :href="link" target="_blank" rel="noopener">
    <slot></slot>
  </a>
</template>
<script>
import { defineComponent, computed } from "vue";

export default defineComponent({
  props: {
    platform: {
      type: String,
      default: "twitter",
    },
    data: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const link = computed(() => {
      switch (props.platform) {
        case "facebook":
          return (
            "http://www.facebook.com/share.php?u=" +
            props.data.url +
            "&title=" +
            props.data.title
          );
        case "twitter":
          return (
            "http://twitter.com/share?url=" +
            encodeURIComponent(props.data.url) +
            "&text=" +
            encodeURIComponent(
              props.data.title +
                (props.data.text ? " - " + props.data.text : "")
            )
          );
      }
      return "";
    });

    return { link };
  },
});
</script>
