
import { defineComponent, ref, onMounted } from "vue";
export default defineComponent({
  props: {
    imagePoster: { type: String, default: null },
    src: { type: String },
  },
  setup(props) {
    const videoRef = ref<HTMLVideoElement | null>(null);
    onMounted(() => {
      if (videoRef.value) {
        videoRef.value.controls = false;
        videoRef.value.playbackRate = 0.6;
        videoRef.value.play();
        videoRef.value.addEventListener("play", () => {
          if (videoRef.value) {
            videoRef.value.controls = false;
          }
        });
      }
    });
    return {
      videoRef,
      videoSrc:
        props.src?.substr(-4) == ".mp4" ? props.src : props.src + ".mp4",
    };
  },
});
