
import { defineComponent, ref, computed } from "vue";
import LinkShare from "@/components/LinkShare.vue";
import FacebookIcon from "@/components/icons/FacebookIcon.vue";
import TwitterIcon from "@/components/icons/TwitterIcon.vue";
import CloseIcon from "@/components/icons/CloseIcon.vue";
import { useClipboard } from "@vueuse/core";
import { onKeyStroke } from "@vueuse/core";
import { useShare } from "@vueuse/core";

export default defineComponent({
  components: {
    CloseIcon,
    FacebookIcon,
    TwitterIcon,
    LinkShare,
  },
  props: {
    isShareModalOpen: {
      type: Boolean,
      required: true,
    },
    title: { type: String, required: true },
    image: { type: String, required: true },
  },
  setup(props, context) {
    const showImage = ref(false);
    const url = ref(window.location.href);
    const { copy, copied, isSupported: isSupportedClipboard } = useClipboard();
    const nativeShareFailed = ref(false);

    const { share, isSupported: isSupportedShare } = useShare();

    const shareData = computed(() => {
      return {
        title: props.title,
        text: "",
        url: url.value,
        image: props.image,
      };
    });
    async function startShare() {
      try {
        await share(shareData.value);
        console.log("MDN shared successfully");
      } catch (err) {
        console.log("Error: " + err);
        nativeShareFailed.value = true;
      }
    }
    onKeyStroke("Escape", () => {
      context.emit("update:isShareModalOpen", false);
    });
    return {
      showImage,
      copy,
      url,
      copied,
      isSupportedClipboard,
      isSupportedShare,
      startShare,
      nativeShareFailed,
      shareData,
    };
  },
});
